import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';

export default class extends Controller {
  static targets = ['dropdown', 'linkManual'];

  connect() {
    const ordrd = this.element.parentElement.dataset.controller === 'sortable';
    this.data.set('ordered', ordrd);
    this.linkManualTarget.classList.toggle('is-hidden', !ordrd);
  }

  sortDefault() {
    Turbolinks.visit(window.location.toString());
  }

  sortName() {
    this.dropdownTarget.click();
    const items = [...document.getElementsByClassName('filter')];

    items.sort((a, b) => {
      const atxt = a.getElementsByClassName('sort-name')[0];
      const btxt = b.getElementsByClassName('sort-name')[0];
      if (atxt == null && btxt == null) {
        return 0;
      }
      return atxt.innerText.localeCompare(btxt.innerText);
    });

    [...document.getElementsByClassName('filter')].forEach(e => {
      this.element.parentElement.removeChild(e);
    });

    items.forEach(e => this.element.parentElement.appendChild(e));
  }

  sortManual() {
    this.dropdownTarget.click();
    const root = document.getElementById('inbox-messages');
    if (!root) {
      return;
    }
    const items = root.getElementsByClassName('filter');

    Array.prototype.forEach.call(items, el => {
      const id = el.dataset.itemId;
      const e2 = el.getElementsByClassName('media-left')[0];
      if (e2.innerHTML.trim() === '') {
        e2.innerHTML = `<span class="icon" draggable="true" data-item-id="${id}">
            <i class="fa fa-bars"></i>
          </span>`;
      } else {
        e2.innerHTML = '';
      }
    });
  }
}
