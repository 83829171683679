import { Controller } from 'stimulus';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import Rails from '@rails/ujs';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';

export default class extends Controller {
  static targets = ['placeholder', 'closer'];

  connect() {
    const url = this.data.get('url');
    this.calendar = null;
    this.placeholderTarget.innerHTML = '';

    this.calendar = new Calendar(this.placeholderTarget, {
      plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
      defaultView: 'dayGridMonth',
      header: {
        left: 'title',
        center: 'dayGridMonth,timeGridWeek,timeGridDay,list',
        right: 'prev,next today'
      },
      //timeZone: 'UTC',
      locales: allLocales,
      locale: this.data.get('locale'),
      selectable: true,
      selectHelper: true,
      editable: true,
      eventStartEditable: true,
      eventResizableFromStart: true,
      eventDurationEditable: true,
      eventLimit: true,
      events: {
        url: `${this.data.get('url')}/tasks.json`,
        extraParams: function() {
          var { pathname, search, hash } = window.location;
          search = search.substr(1);

          if (search && search !== '') {
            return search.split('&').reduce((res, item) => {
              const parts = item.split('=');
              res[parts[0]] = parts[1];
              return res;
            }, {});
          }

          return {};
        }
      },

      select: e => {
        const spanner = document.getElementById('spanner');
        spanner.innerText = `${e.start}-${e.end}`;
        //$.getScript('/events/new', function() {});
        //this.calendar.fullCalendar('unselect');
      },

      eventDrop: e => {
        const data = new FormData();

        data.append('ticket[id]', e.event.id);
        data.append('ticket[for]', e.event.start);

        if (e.event.end) {
          data.append('ticket[end_at]', e.event.end);
        }
        this.data.set('redirect', '1');
        Rails.ajax({
          url: `${url}/${e.event.id}`,
          data: data,
          type: 'PATCH',
          error: data => {
            e.revert();
          },
          complete: e => {
            this.data.set('redirect', '0');
          }
        });
      },

      eventResize: e => {
        const data = new FormData();

        data.append('ticket[id]', e.event.id);
        data.append('ticket[for]', e.event.start);

        if (e.event.end) {
          data.append('ticket[end_at]', e.event.end);
        }

        this.data.set('redirect', '1');
        Rails.ajax({
          url: `${url}/${e.event.id}`,
          data: data,
          type: 'PATCH',
          error: data => {
            e.revert();
          },
          complete: e => {
            this.data.set('redirect', '0');
          }
        });
      },

      eventClick: e => {
        this.lastClickedEvent = e.event;
        this.data.set('redirect', '1');

        Rails.ajax({
          url: `${url}/${e.event.id}/edit`,
          type: 'GET',
          dataType: 'text/html',
          accept: 'text/html',
          error: data => {
            //this.contentTarget.innerHTML = '<pre>' + data + '</pre>';
          },
          success: (data, status, xhr) => {
            const modal = document.getElementById('modal');
            if (modal) {
              // Create modal
              const back = data.querySelector('a[data-target="form.backlink"]');
              if (back) {
                back.dataset.target = '';
              }
              modal.innerHTML = data.querySelector('.columns').innerHTML;
              modal.classList.add('is-active');
            }
          },
          complete: e => {
            this.data.set('redirect', '0');
          }
        });
      }
    });

    this.calendar.render();
  }

  // Prevent Turbolink redirection
  redirect(e) {
    if (this.data.get('redirect') === '1') {
      Turbolinks.clearCache();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  update() {
    if (this.lastClickedEvent) {
      this.lastClickedEvent.source.refetch();
    }
  }

  clear() {
    if (this.calendar !== null) {
      this.calendar.destroy();
      this.calendar = null;
      this.placeholderTarget.innerHTML = '';
    }
  }

  disconnect() {
    if (this.calendar !== null) {
      this.calendar.destroy();
      this.calendar = null;
    }
    this.placeholderTarget.innerHTML = '';
  }
}
