import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggle', 'show', 'action'];

  toggle(e) {
    let isActive = false;

    if (this.hasToggleTarget) {
      isActive = this.toggleTarget.classList.toggle('is-active');
    }

    if (this.hasShowTarget) {
      this.showTarget.classList.toggle('is-hidden');
    }
  }

  show(e) {
    if (this.hasToggleTarget) {
      this.toggleTarget.classList.add('is-active');
    }

    if (this.hasShowTarget) {
      this.showTarget.classList.remove('is-hidden');
    }
  }

  hide(e) {
    if (this.hasToggleTarget) {
      this.toggleTarget.classList.remove('is-active');
    }

    if (this.hasShowTarget) {
      this.showTarget.classList.add('is-hidden');
    }
  }

  activate(e) {
    this.actionTargets.forEach(action => {
      action.classList.toggle('is-active', action === e.currentTarget);

      const target = document.getElementById(action.dataset.id);

      target.classList.toggle('is-active', action === e.currentTarget);
      target.classList.toggle('is-hidden', action !== e.currentTarget);
    });
  }
}
