import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['preview', 'range', 'folder'];

  preview(e) {
    // /user1/project1/sales/1
    Rails.ajax({
      url: `${window.location.href}/${e.currentTarget.id}/preview?query=${this.queryTarget.value}`,
      type: 'GET',
      dataType: 'html',
      success(data) {
        console.log(data);
      }
    });
  }

  success(e) {
    const data = e.detail[2].response;
    this.previewTarget.innerHTML = data;
  }

  all(e) {
    e.preventDefault();
    Turbolinks.visit('/');
  }

  setfolder() {}

  change(e) {
    this.data.set('range', e.currentTarget.id);
    this.preview();
  }

  get index() {
    return parseInt(this.data.get('index') || 0);
  }

  set index(value) {
    this.data.set('index', value);
  }
}
