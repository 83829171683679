import { Controller } from 'stimulus';

export default class extends Controller {

  close(e) {
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.parentElement.classList.add('hidden');
  }
}
