import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

// <div class="inbox-messages" id="inbox-messages" data-controller="sortable">
//  <%= render @folder.tickets.indexed.reorder(:order_no, :id), folder: @folder %>
// </div>
// ...
// <div class="card filter" id="item_<%= ticket.id %>" data-target="sortable.item" data-item-id="<%= ticket.id %>">
//   ...
//   <span class="icon" draggable="true" data-item-id="<%= ticket.id %>">
//     <i class="fa fa-bars"></i>
//   </span>

export default class extends Controller {
  static targets = ['item'];

  connect() {
    this.itemTargets.forEach(item => {
      item.addEventListener('drop', this.drop.bind(this), false);
      item.addEventListener('dragstart', this.dragStart.bind(this), false);
      item.addEventListener('dragover', this.dragOver.bind(this), false);
      item.addEventListener('dragenter', this.dragEnter.bind(this), false);
      item.addEventListener('dragleave', this.dragLeave.bind(this), false);
      item.addEventListener('dragend', this.dragEnd.bind(this), false);
    });
  }

  dragStart(event) {
    const id = event.target.getAttribute('data-item-id');
    event.dataTransfer.setData('application/drag-key', id);

    const dragster = document.getElementById('item_' + id);
    event.dataTransfer.setDragImage(dragster, 25, 25);
    event.dataTransfer.effectAllowed = 'move';
    event.target.style.opacity = 0.3;
  }

  drop(event) {
    let data = event.dataTransfer.getData('application/drag-key');

    const dropTarget =
      event.target && event.target.closest('[data-target="sortable.item"]');

    const draggedItem = document.getElementById('item_' + data);

    if (event.offsetY < dropTarget.clientHeight / 2) {
      dropTarget.insertAdjacentElement('beforebegin', draggedItem);
    } else {
      dropTarget.insertAdjacentElement('afterend', draggedItem);
    }

    event.preventDefault();

    const order = [];
    this.itemTargets.forEach(item => order.push(item.dataset['itemId']));
    var formData = new FormData();
    formData.append('reorder', JSON.stringify(order))

    Rails.ajax({
      url: `${window.location.href}/reorder`,
      type: 'POST',
      dataType: 'json',
      data: formData
    });
  }

  dragOver(event) {
    event.preventDefault();
    return true;
  }

  dragEnter(event) {
    const el =
      event.target && event.target.closest('[data-target="sortable.item"]');

    if (el) {
      event.preventDefault();
      // Set the dropEffect to move
      event.dataTransfer.dropEffect = 'move';
      this.itemTargets.forEach(item => {
        item.classList.toggle(
          'margin-top-07',
          item === el && event.offsetY < el.clientHeight / 2
        );
        item.classList.toggle(
          'margin-bottom-07',
          item === el && event.offsetY >= el.clientHeight / 2
        );
      });
    }
  }

  dragLeave(event) {}

  dragEnd(event) {
    this.itemTargets.forEach(item => {
      item.classList.remove('margin-top-07', 'margin-bottom-07');
    });

    event.target.style.opacity = ''; // Reset opacity when dragging ends
    // TODO: Post to server
  }
}
