import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal', 'close'];

  unlockDocumentBody() {
    document.body.style.position = null;
    //document.body.style.overflow = null;
    document.body.style.width = null;
  }

  lockDocumentBody() {
    document.body.style.position = 'fixed';
    // document.body.style.overflow = 'hidden';
    document.body.style.width = '100%';
  }

  close() {
    this.modalTarget.classList.remove('is-active');
    this.modalTarget.innerText = '';
  }

  initialize() {
    this.observer = new MutationObserver(() => {
      if (this.modalTarget.classList.contains('is-active')) {
        this.lockDocumentBody();
        const af = this.modalTarget.querySelector('[autofocus]');
        if (af) {
          af.focus();
        }
      } else {
        this.unlockDocumentBody();
      }
    });

    this.observer.observe(this.modalTarget, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  disconnect() {
    this.observer.disconnect();
  }
}
