import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';
import Rails from '@rails/ujs';
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = ['output', 'delete'];

  connect() {}

  check() {
    const root = document.getElementById('inbox-messages');
    if (!root) {
      return;
    }

    const items = root.getElementsByClassName('filter');

    Array.prototype.forEach.call(items, el => {
      const id = el.dataset.itemId;
      const e2 = el.getElementsByClassName('media-left')[0];
      if (e2.innerHTML.trim() == '') {
        e2.innerHTML = `<input type="checkbox" class="lav5-checker" data-item-id="${id}">`;
      } else {
        e2.innerHTML = '';
      }
    });
  }

  refresh() {
    Turbolinks.visit(window.location.toString());
  }

  trash() {
    if (this.hasDeleteTarget) {
      this.deleteTarget.click();
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel'
    }).then(result => {
      const href = window.location.href.split('#')[0];
      const data = [
        ...document.querySelectorAll(
          "input[type='checkbox'].lav5-checker:checked"
        )
      ].map(el => el.dataset.itemId);

      Rails.ajax({
        url: `${href}/remove`,
        type: 'POST',
        dataType: 'application/json',
        data: data,
        error: data => {},
        success: (data, status, xhr) => {}
      });
    });
  }

  inbox() {}

  exclamation() {}

  folder() {}

  tag() {}
}
