import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['email'];

  preview(e) {
    const href = window.location.href.split('#')[0];
    // /user1/project1/sales/1
    Rails.ajax({
      url: `${href}/preview?item=${item}`,
      type: 'POST',
      dataType: 'html',
      data: {
        user: {
          email: this.emailTarget.value
        }
      },
      error: data => {
      },
      success: (data, status, xhr) => {
      }
    });
  }
}