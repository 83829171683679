import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['item'];

  connect() {
    if (this.isMobile()) {
      return;
    }

    const focusedId = this.data.get('focused');
    const item = this.itemTargets.find(el => el.id === focusedId);
    if (item) {
      this.itemTargets.forEach(el => {
        el.classList.toggle('active', el.id === focusedId);
      });
      return;
    }

    if (this.hasItemTarget) {
      this.itemTargets[0].classList.add('active');
    }
  }

  isMobile() {
    return window.matchMedia('(max-width: 700px)').matches;
  }

  preview(e) {
    const href = this.data.get('url') || window.location.pathname;

    if (this.isMobile()) {
      Turbolinks.visit(`${href}/${e.currentTarget.id}`);
      return;
    }

    this.setActive(e.currentTarget);

    // /v/1/clients/:id/preview
    Rails.ajax({
      url: `${href}/${e.currentTarget.id}/preview`,
      type: 'GET',
      dataType: 'html',
      success(data, status, xhr) {
        const view = document.getElementById('partner_preview');
        view.innerHTML = xhr.response;
      },
      error(data) {
        const view = document.getElementById('partner_preview');
        view.innerHTML = data;
      }
    });
  }

  setActive(item) {
    const reExp = /focused=\d+/;
    const { pathname, search, hash } = window.location;
    const newSrch = search.replace(reExp, `focused=${item.id}`);
    let url = `${pathname}${newSrch}${hash}`;

    if (!url.match(reExp)) {
      if (search[0] !== '?') {
        url = `${pathname}?focused=${item.id}${hash}`;
      } else {
        url = `${pathname}${search}&focused=${item.id}${hash}`;
      }
    }

    this.itemTargets.forEach(el => {
      el.classList.toggle('active', el.id === item.id);
    });

    window.history.pushState({ turbolinks: true, url }, '', url);
  }
}
