import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'input',
    'button',
    'hidden',
    'name',
    'qty',
    'price',
    'results',
    'item'
  ];

  debounce(func, wait, immediate) {
    let timeout;
    return function() {
      const context = this;
      const args = arguments;
      const later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  hideresults() {
    return this.data.get('keep-results') !== '1';
  }

  resultsHidden() {
    if (!this.hasButtonTarget) {
      return this.resultsTarget.hidden;
    }
    return !this.buttonTarget.classList.contains('is-active');
  }

  menuVisible(setVisible) {
    if (!this.hasButtonTarget) {
      this.resultsTarget.hidden = !setVisible;
      return;
    }

    if (setVisible) {
      this.buttonTarget.classList.add('is-active');
      this.buttonTarget.setAttribute('aria-expanded', 'true');
      return;
    }

    this.buttonTarget.classList.remove('is-active');
    this.buttonTarget.setAttribute('aria-expanded', 'false');
  }

  connect() {
    this.menuVisible(!this.hideresults());
    this.data.set('text', this.inputTarget.value);
    if (this.hasHiddenTarget) {
      this.data.set('value', this.hiddenTarget.value);
    }

    this.inputTarget.setAttribute('autocomplete', 'off');
    this.inputTarget.setAttribute('spellcheck', 'false');

    this.mouseDown = false;

    this.onInputChange = this.debounce(this.onInputChange.bind(this), 300);
    this.onResultsClick = this.onResultsClick.bind(this);
    this.onResultsMouseDown = this.onResultsMouseDown.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onKeydown = this.onKeydown.bind(this);
  }

  disconnect() {}

  clear() {
    if (this.hasQtyTarget) this.qtyTarget.value = '';
    if (this.hasPriceTarget) this.priceTarget.value = '';
    if (this.hasHiddenTarget) this.hiddenTarget.value = '';
    this.inputTarget.value = '';

    this.data.set('value', '');
    this.data.set('text', '');
  }

  sibling(next) {
    const options = Array.from(this.resultsTarget.querySelectorAll('.card'));
    const selected = this.resultsTarget.querySelector('.is-selected');
    const index = options.indexOf(selected);
    const sibling = next ? options[index + 1] : options[index - 1];
    const def = next ? options[0] : options[options.length - 1];
    return sibling || def;
  }

  select(target) {
    this.itemTargets.forEach(item => {
      item.classList.toggle('is-selected', item === target);
      item.classList.toggle('is-active', item === target);
    });

    if (this.hasHiddenTarget) this.hiddenTarget.value = target.dataset.id;
    if (this.hasNameTarget) this.nameTarget.value = target.dataset.name;
    if (this.hasQtyTarget && document.activeElement !== this.qtyTarget) {
      this.qtyTarget.value = target.dataset.qty || 1;
    }
    if (this.hasPriceTarget) this.priceTarget.value = target.dataset.price;
    // selected.dataset.tax
    // selected.dataset.rebate
  }

  revertValue() {
    this.inputTarget.value = '';

    // ... or set it to old Value if there is not search.name
    if (!this.hasNameTarget) {
      this.inputTarget.value = this.data.get('text');
      if (this.hasHiddenTarget) {
        this.hiddenTarget.value = this.data.get('value');
      }
    }
  }

  onKeydown(event) {
    switch (event.key) {
      case 'Escape':
        event.stopPropagation();
        event.preventDefault();

        // First Esc - close menu
        if (!this.resultsHidden() && this.hideresults()) {
          this.menuVisible(false);
          return;
        }

        // Second Esc clear input
        this.revertValue();

        break;
      case 'ArrowDown':
        {
          const item = this.sibling(true);
          if (item) this.select(item);
          event.preventDefault();
        }
        break;
      case 'ArrowUp':
        {
          const item = this.sibling(false);
          if (item) this.select(item);
          event.preventDefault();
        }
        break;
      //case 'Tab':
      //  {
      //    const selected = this.resultsTarget.querySelector('.is-selected');
      //    if (selected) {
      //      this.commit(selected);
      //    }
      //  }
      //  break;
      case 'Enter':
        {
          event.preventDefault();
          event.stopPropagation();
          const selected = this.resultsTarget.querySelector('.is-selected');

          if (selected && !this.resultsHidden()) {
            this.commit(selected);
            event.preventDefault();
          }
        }
        break;
    }
  }

  onInputFocus() {
    // this.fetchResults();
  }

  onInputBlur() {
    if (this.mouseDown) return;
    this.menuVisible(!this.hideresults());
    this.revertValue();
  }

  itemClicked(e) {
    this.commit(e.target);
  }

  commit(selected) {
    if (selected.classList.contains('is-disabled')) return;

    if (selected instanceof HTMLAnchorElement && selected.dataset.external) {
      selected.click();
      this.menuVisible(!this.hideresults());
      return;
    }

    const textValue = selected.dataset.name;
    const detail = {
      id: this.data.get('id'),
      value: selected.dataset.id || textValue,
      textValue: textValue,
      code: selected.dataset.code,
      qty: this.hasQtyTarget ? this.qtyTarget.value || 1 : 1,
      price: this.hasPriceTarget ? this.priceTarget.value || 0 : 0
    };

    this.data.set('value', detail.value);
    this.data.set('text', detail.textValue);

    this.inputTarget.value = detail.textValue;

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = detail.value;
    }

    const clickAdd = document.getElementById(this.data.get('target-id'));
    if (clickAdd) {
      clickAdd.dispatchEvent(
        new CustomEvent('search:change', {
          bubbles: true,
          detail
        })
      );
    }

    this.menuVisible(!this.hideresults());
    this.inputTarget.focus();
    this.debounce(this.inputTarget.select(), 300);
  }

  onResultsClick(event) {
    if (!(event.target instanceof Element)) return;
    const selected = event.target.closest('[data-target="search.item"]');
    if (selected) {
      this.select(selected);
      this.inputTarget.focus();
    }
  }

  onResultsDblClick(event) {
    this.onResultsClick(event);
    this.go(event);
  }

  onResultsMouseDown() {
    this.mouseDown = true;
    this.resultsTarget.addEventListener(
      'mouseup',
      () => (this.mouseDown = false),
      { once: true }
    );
  }

  onInputChange() {
    this.element.removeAttribute('value');
    this.fetchResults();
  }

  identifyOptions() {
    let id = 0;
    for (const el of this.resultsTarget.querySelectorAll(
      '[role="option"]:not([id])'
    )) {
      el.id = `${this.resultsTarget.id}-option-${id++}`;
    }
  }

  fetchResults() {
    const query = this.inputTarget.value.trim();

    if (!query || query.length < this.minLength) {
      this.menuVisible(!this.hideresults());
      return;
    }

    if (!this.src) return;

    const url = new URL(this.src, window.location.href);

    this.element.dispatchEvent(new CustomEvent('loadstart'));
    const formData = new FormData();
    formData.append(this.inputTarget.name, query);

    Rails.ajax({
      url: url.toString(),
      type: 'POST',
      accept: 'html',
      data: formData,
      success: (data, statux, xhr) => {
        this.resultsTarget.innerHTML = xhr.response;
        this.identifyOptions();
        const hasResults = !!this.resultsTarget.querySelector(
          '[data-target="search.item"]'
        );
        this.menuVisible(true); // hasResults
        this.element.dispatchEvent(new CustomEvent('load'));
        this.element.dispatchEvent(new CustomEvent('loadend'));

        const item = this.sibling(true);
        if (item) this.select(item);
      }
    });
  }

  open() {
    if (!this.resultsHidden()) return;
    this.menuVisible(true);
    this.element.setAttribute('aria-expanded', 'true');
    this.element.dispatchEvent(
      new CustomEvent('toggle', {
        detail: { input: this.input, results: this.results }
      })
    );
  }

  close() {
    if (this.resultsHidden()) return;
    this.menuVisible(!this.hideresults());
    this.inputTarget.removeAttribute('aria-activedescendant');
    this.element.setAttribute('aria-expanded', 'false');
    this.element.dispatchEvent(
      new CustomEvent('toggle', {
        detail: { input: this.input, results: this.results }
      })
    );
  }

  go(e) {
    e.preventDefault();
    e.stopPropagation();

    // if (this.inputTarget !== document.activeElement) return;

    const selected = this.resultsTarget.querySelector('.is-selected');

    if (selected && !this.resultsHidden()) {
      this.commit(selected);
    }
  }

  get src() {
    return this.data.get('url');
  }

  get minLength() {
    const minLength = this.data.get('min-length');
    if (!minLength) {
      return 0;
    }
    return parseInt(minLength, 10);
  }
}
