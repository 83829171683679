import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['location'];

  connect() {}

  update(e) {
    if (e.detail.id !== 'partner_id') {
      return;
    }

    Rails.ajax({
      url:
        this.locationTarget.dataset.url +
        '?search=location_list&focused=' +
        e.detail.value,
      type: 'GET',
      accept: 'html',
      success: (data, statux, xhr) => {
        this.locationTarget.value = '';
        this.locationTarget.innerHTML = xhr.response;
      }
    });
  }
}
