import { Controller } from 'stimulus';
import Trix from 'trix';

export default class extends Controller {
  static targets = ['editor'];

  initialize() {
    Trix.config.blockAttributes.heading2 = {
      tagName: 'h2',
      terminal: true,
      breakOnReturn: true,
      group: false
    };

    Trix.config.blockAttributes.heading3 = {
      tagName: 'h3',
      terminal: true,
      breakOnReturn: true,
      group: false
    };

    Trix.config.blockAttributes.section = {
      tagName: 'div',
      nestable: true
    };
  }

  connect() {
    this.element.addEventListener(
      'trix-initialize',
      this.initTrix.bind(this),
      false
    );
  }

  disconnect() {
    this.element.removeEventListener(
      'trix-initialize',
      this.initTrix.bind(this),
      false
    );
  }

  initTrix(event) {
    if (document.getElementById(`file-${event.target.trixId}`)) {
      return;
    }
    const toolbar = event.target.toolbarElement;
    const btools = toolbar.querySelector('[data-trix-button-group="block-tools"]');
    const ttools = toolbar.querySelector('[data-trix-button-group="text-tools"]');
    const dialogs = toolbar.querySelector('.trix-dialogs');

    ttools.insertAdjacentHTML(
      'beforeend',
      this.contentButton('attach', 'Attach file', '')
    );

    dialogs.insertAdjacentHTML(
      'beforeend',
      this.uploadDialog(event.target.trixId)
    );

    btools.insertAdjacentHTML(
      'beforeend',
      this.toolButton('heading2', 'Subheading', 'H2')
    );

    btools.insertAdjacentHTML(
      'beforeend',
      this.toolButton('heading3', 'Subheading', 'H3')
    );
  }

  toolButton(attributeId, tooltip, title) {
    return `<button type="button" class="trix-button" data-trix-attribute="${attributeId}" title="${tooltip}">${title}</button>`;
  }

  contentButton(attributeId, tooltip, title) {
    return `
    <button type="button"
      class="trix-button trix-button--icon trix-button--icon-attach"
      data-trix-attribute="${attributeId}"
      data-trix-key="+" title="${tooltip}" tabindex="-1">
      ${title}
    </button>`;
  }

  uploadDialog(trixId) {
    return `
    <div class="trix-dialog trix-dialog--attach" data-trix-dialog="attach" data-trix-dialog-attribute="attach">
      <div class="trix-dialog__attach-fields">
        <input id="file-${trixId}" type="file" class="trix-input trix-input--dialog">
        <div class="trix-button-group">
          <input type="button" class="trix-button trix-button--dialog" data-action="click->trix#uploadButtonClick" data-id="${trixId}"
            value="Attach" data-trix-method="removeAttribute">
          <input type="button" class="trix-button trix-button--dialog" value="Cancel" data-trix-method="removeAttribute">
        </div>
      </div>
    </div>`;
  }

  uploadButtonClick(e) {
    const trixId = e.target.dataset.id;
    const trix = document.querySelector(`[trix-id="${trixId}"]`);
    let fileInput = document.getElementById(`file-${trixId}`);
    if (fileInput.files.length === 0) {
      console.log('nothing selected');
      return;
    }

    Array.from(fileInput.files).forEach(file => {
      trix.editor.insertFile(file);
    });
  }
}
