import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['source'];

  filter() {
    const lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase();
    const root = document.getElementById('inbox-messages');
    if (!root) {
      return;
    }

    const items = root.getElementsByClassName('filter');

    Array.prototype.forEach.call(items, el => {
      const filterableKey = el.getAttribute('data-filter-key') || el.innerText;

      el.classList.toggle(
        'is-hidden',
        !filterableKey.toLowerCase().includes(lowerCaseFilterTerm)
      );
    });
  }
}
