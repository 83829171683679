import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['display'];

  connect() {
    const value = this.element.innerText.trim();

    if (this.data.get('locale') === '') {
      this.data.set('locale', window.navigator.language);
    }

    if (value === '') return;

    this.data.set('value', value);
    this.element.setAttribute('title', this.format_datetime(value));
    this.update();
  }

  update() {
    const value = this.data.get('value');

    switch (this.data.get('type')) {
      case 'date':
        this.element.innerText = this.format_date(value);
        break;
      case 'time':
        this.element.innerText = this.format_time(value);
        break;
      case 'datetime':
        this.element.innerText = this.format_datetime(value);
        break;
      case 'datemin':
        this.element.innerText = this.format_datemin(value);
        break;
      case 'dateago':
        this.element.innerText = this.format_dateago(value);
        break;
    }
  }

  clicked() {
    if (this.data.get('type') === 'dateago') {
      this.data.set('type', 'datemin');
      this.update();
      return;
    }

    if (this.data.get('type') === 'datemin') {
      this.data.set('type', 'dateago');
      this.update();
    }
  }

  format_date(value) {
    const date = value || this.data.get('value');

    return new Date(date).toLocaleDateString(this.data.get('locale'), {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  }

  format_time(value) {
    const date = value || this.data.get('value');

    return new Date(date).toLocaleDateString({
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    });
  }

  format_datetime(value) {
    const date = value || this.data.get('value');

    return new Date(date).toLocaleString(
      this.data.get('locale'),{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });
  }

  format_datemin(value) {
    const date = value || this.data.get('value');
    const d = new Date(date);
    const now = new Date();

    // Is Today
    if (
      d.getFullYear() === now.getFullYear() &&
      d.getMonth() === now.getMonth() &&
      d.getDay() === now.getDay()
    ) {
      return `${d.getHours()}:${d.getMinutes()}`;
    }

    // Is this Year
    if (d.getFullYear() === now.getFullYear()) {
      return d.toLocaleString(this.data.get('locale'), {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }

    return this.format_datetime(value);
  }

  format_dateago(value) {
    const date = value || this.data.get('value');
    let ms = new Date() - new Date(date);
    let timerId;
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = 24 * hour,
      month = 30 * day,
      year = 12 * month;
    const prefix = ms < 0 ? 'in ' : '';
    const sufix = ms < 0 ? '' : ' ago';
    let fragment = 'min',
      t = 1;

    ms = Math.abs(ms);

    if (ms < 10 * second) {
      timerId = setTimeout(() => this.update(), 2000);
      return 'just now';
    }

    if (ms < minute) {
      timerId = setTimeout(() => this.update(), 2000);
      return '< 1 min';
    }

    if (ms < hour) {
      // fragment = 'min';
      t = (ms / minute) | 0;

      timerId = setTimeout(() => {
        timerId = setTimeout(this.update(), 2000);
      }, 2000);
    } else if (ms < day) {
      fragment = 'hour';
      t = (ms / hour) | 0;
    } else if (ms < month) {
      fragment = 'day';
      t = (ms / day) | 0;
    } else if (ms < year) {
      fragment = 'month';
      t = (ms / month) | 0;
    } else {
      fragment = 'year';
      t = (ms / year) | 0;
    }

    if (t > 1) {
      return `${prefix}${t} ${fragment}s${sufix}`;
    }

    return `${prefix}${t} ${fragment}${sufix}`;
  }
}
