import { Controller } from 'stimulus';
import { eventPath } from 'stlib';

export default class extends Controller {
  static targets = ['menu', 'button', 'title', 'input'];

  toggle(e) {
    if (eventPath(e).includes(this.menuTarget)) {
      return;
    }

    e.preventDefault();

    this.buttonTarget.classList.toggle('is-active');
    this.buttonTarget.setAttribute('aria-expanded', this.isActive());
  }

  pullup(e) {
    if (!this.buttonTarget.classList.contains('is-active')) {
      return;
    }
    if (eventPath(e).includes(this.menuTarget)) {
      return;
    }

    if (eventPath(e).includes(this.buttonTarget)) {
      return;
    }

    this.buttonTarget.classList.remove('is-active');
    this.buttonTarget.setAttribute('aria-expanded', this.isActive());
  }

  select(e) {
    if (this.hasInputTarget) {
      this.inputTarget.value = e.currentTarget.dataset.id;
    }

    this.titleTarget.innerHTML = e.currentTarget.innerHTML;
    this.buttonTarget.classList.remove('is-active');
    this.buttonTarget.setAttribute('aria-expanded', this.isActive());
  }

  isActive() {
    if (this.buttonTarget.classList.contains('is-active')) {
      return 'true';
    }
    return 'false';
  }
}
