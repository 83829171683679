import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';
import Swal from 'sweetalert2/dist/sweetalert2.all.min';

export default class extends Controller {
  submit() {}

  prevent(e) {
    e.stopPropagation();
  }

  // After retreiving partial and displayed in modal
  //  * its backLink is set to calling window.location
  //  * History is set to new location equaling modlinks href
  success(e) {
    const modal = document.getElementById('modal');
    if (modal !== null) {
      // Create modal
      modal.innerHTML = e.detail[2].response;
      modal.classList.add('is-active');

      // Set last item with class back-link to current location
      const backLinks = modal.getElementsByClassName('back-link');
      if (backLinks.length > 0) {
        backLinks[backLinks.length - 1].href = window.location;
      }

      // Set browser history
      Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(
        this.element.href,
        Turbolinks.uuid()
      );
    }
  }

  // Remote failed - display error
  error(e) {
    let msg = { title: e.detail[1], detail: '' };
    try {
      [msg] = JSON.parse(e.detail[0]).errors;
    } finally {
    }

    Swal.fire({
      title: this.data.get('text') || msg.title,
      text: msg.detail,
      type: 'error',
      animation: false,
      showCancelButton: false,
      confirmButtonText: 'Cancel',
      allowEnterKey: true,
      allowEscapeKey: true
    });
  }

  // Delete modlink displays confirmation dialog
  delete(e) {
    if (!this.data.get('message')) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    Swal.fire({
      title: this.data.get('message') || 'Are you sure?',
      text: '' || this.data.get('text'),
      type: 'warning',
      animation: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      allowEnterKey: true,
      allowEscapeKey: true
    }).then(result => {
      if (result.value) {
        this.data.delete('message');
        this.element.click();
      }
    });
  }

  // Delete modlink displays confirmation dialog
  post(e) {
    if (!this.data.get('message')) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    Swal.fire({
      title: this.data.get('message') || 'Are you sure?',
      text: '' || this.data.get('text'),
      type: 'warning',
      animation: false,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      allowEnterKey: true,
      allowEscapeKey: true
    }).then(result => {
      if (result.value) {
        this.data.delete('message');
        this.element.click();
      }
    });
  }
}
