import Flatpickr from 'stimulus-flatpickr';
import 'flatpickr/dist/themes/dark.css';
//import 'flatpickr/dist/themes/light.css';
//import 'flatpickr/dist/themes/airbnb.css';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';

export default class extends Flatpickr {
  initialize() {
    //define locale and global flatpickr settings for all datepickers
    this.config = {
      // altInput: true
      // locale: this.locale,
      // showMonths: 2,
      plugins: [
        //  new confirmDatePlugin({})
      ]
    };

    // all flatpickr hooks are available as callbacks in your Stimulus controller
    //  change(selectedDates, dateStr, instance) {
    //    console.log("the callback returns the selected dates", selectedDates);
    //    console.log("but returns it also as a string", dateStr);
    //    console.log("and the flatpickr instance", instance);
    // }
  }
}
