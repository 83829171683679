import { Controller } from 'stimulus';

// <h1 data-controller="inplace"
//     data-action="click->inplace#edit"
//     data-target="inplace.editor">
//   Here is a header
// </h1>

export default class extends Controller {
  static targets = ['editor'];

  edit(e) {
    e.preventDefault();

    let editor = document.createElement('input');
    editor.value = e.target.innerText;

    let style = window.getComputedStyle(e.target);
    editor.style.cssText = style.cssText;

    editor.addEventListener(
      'keypress',
      function(event) {
        const key = event.which || event.keyCode;
        if (key === 13 || key === 27) {
          this.editorTarget.innerText = editor.value;
          this.editorTarget.classList.remove('is-hidden');
          editor.remove();
        }
      }.bind(this)
    );

    let selection = window.getSelection();
    editor.setSelectionRange(selection.anchorOffset, selection.anchorOffset);

    this.editorTarget.insertAdjacentElement('afterend', editor);
    this.editorTarget.classList.add('is-hidden');
  }

  save() {}
}
