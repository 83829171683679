import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'item',
    'delete',
    'add',
    'total',
    'tax',
    'totalLabel',
    'taxLabel'
  ];

  connect() {
    window.setInterval(() => {
      if (this.data.get('invalid') === '1') {
        this.calculate();
      }
    }, 300);

    // this.addEventListener('items:invalidate', this.invalidate);
  }

  invalidate() {
    this.data.set('invalid', '1');
  }

  showall() {
    this.element.getElementsByClassName('item-destory').value = '0';
    this.itemTargets.forEach(item => item.classList.remove('is-hidden'));
  }

  remove(e) {
    e.preventDefault();

    const itemId = e.currentTarget.dataset.id;
    const item = this.itemTargets.find(itm => itm.dataset.id === itemId);
    document.getElementById(`item_${itemId}_destroy`).value = '1';
    item.classList.add('is-hidden');
  }

  // Replace item od with time generated value, append before add link
  add(e) {
    if (e) {
      e.preventDefault();
    }

    const time = new Date().getTime();
    const regexp = new RegExp(this.addTarget.dataset.id, 'g');
    const data = this.addTarget.dataset.fields.replace(regexp, time);

    this.addTarget.insertAdjacentHTML('beforebegin', data);

    this.data.set('last-insert-id', time);
  }

  addRemote(e) {
    this.add();

    const lastId = this.data.get('last-insert-id');
    const item = this.itemTargets.find(item => item.dataset.id === lastId);

    setTimeout(() => {
      item.dispatchEvent(
        new CustomEvent('item:update', {
          bubbles: true,
          detail: e.detail
        })
      );
    }, 1);
  }

  calculate() {
    this.data.set('invalid', '');

    const format = { minimumFractionDigits: 2 };

    if (this.hasTotalLabelTarget) {
      let totalPrice = 0;
      this.totalTargets.forEach(t => {
        totalPrice += Number(t.value);
      });

      this.totalLabelTarget.innerText = totalPrice.toLocaleString('en', format);
    }

    if (this.hasTaxLabelTarget) {
      let totalTax = 0;
      this.taxTargets.forEach(t => {
        totalTax += Number(t.value);
      });

      this.taxLabelTarget.innerText = taxPrice.toLocaleString('en', format);
    }
  }
}
