import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = ['dropdown'];

  dropdown(e) {
    e.stopPropagation();
    e.preventDefault();
    this.dropdownTarget.classList.toggle('hidden');
  }

  pullup(e) {
    if (e.path.includes(this.dropdownTarget)) {
      e.preventDefault();
      return;
    }
    this.dropdownTarget.classList.add('hidden');
  }
}