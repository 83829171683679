import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['note', 'editbutton', 'closebutton', 'closed'];

  debounce(func, wait, immediate) {
    let timeout;
    return function() {
      const context = this;
      const args = arguments;
      const later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  connect() {
    this.debounce(this.updateCloseButtonCaption(), 500);
  }

  updateCloseButtonCaption() {
    const btn = this.closebuttonTarget;

    if (this.closedTarget.value !== '') {
      btn.classList.remove('is-info');
      btn.classList.add('is-danger');
      btn.innerText = btn.dataset.reopen;
      return;
    }
    btn.classList.remove('is-danger');
    btn.classList.add('is-info');
    btn.innerText = btn.dataset.close;
  }

  close() {
    const fp = this.closedTarget._flatpickr;

    if (fp.input.value !== '') {
      fp.clear();
      this.updateCloseButtonCaption();
      return;
    }
    fp.setDate(new Date());
    this.updateCloseButtonCaption();
  }

  edit() {
    const textEl = document.getElementById('x');

    if (this.data.get('editing') === '1') {
      this.noteTarget.innerHTML = textEl.value;
      this.data.set('editing', '0');
      this.editbuttonTarget.innerText = this.editbuttonTarget.dataset.edit;
      return;
    }

    this.noteTarget.innerHTML = `
      <trix-editor input="x"
                   class="trix-content"
                   data-controller="editor"
                   data-action="@trix-change->editor#click"
                   data-target="editor.editor"
                   data-direct-upload-url="${this.noteTarget.dataset.url}"
                   data-blob-url-template="${this.noteTarget.dataset.template}">
      </trix-editor>`;

    this.editbuttonTarget.innerText = this.editbuttonTarget.dataset.save;
    this.data.set('editing', '1');
  }
}
