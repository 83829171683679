import { Controller } from 'stimulus';
// import Turbolinks from 'turbolinks';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['content', 'tab'];

  connect() {
    const hash = window.location.hash.substr(1);
    const item =
      this.tabTargets.find(tab => tab.dataset.id === hash) ||
      this.tabTargets[0];

    if (item && !item.classList.contains('is-active')) {
      this.displayTab(item);
      return;
    }
  }

  preview(e) {
    e.preventDefault();
    this.displayTab(e.currentTarget);
  }

  refresh(e) {
    const item = this.tabTargets.find(tab =>
      tab.classList.contains('is-active')
    );

    e.preventDefault();
    if (item) {
      this.displayTab(item);
    }
  }

  success() {}

  displayTab(tab) {
    const href = this.data.get('url') || window.location.pathname;
    const item = tab.dataset.id;

    this.setActive(tab);

    Rails.ajax({
      url: `${href}/${this.data.get('id')}/preview?item=${item}`,
      type: 'GET',
      dataType: 'html',
      error: data => {
        this.contentTarget.innerHTML = '<pre>' + data + '</pre>';
      },
      success: (data, status, xhr) => {
        this.contentTarget.innerHTML = xhr.response;
      }
    });
  }

  setActive(target) {
    const url = `#${target.dataset.id}`;

    this.tabTargets.forEach(tab =>
      tab.classList.toggle('is-active', tab === target)
    );

    window.history.replaceState({turbolinks: true, url}, target.innerText, url);
  }
}
