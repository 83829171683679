import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['preview'];

  submit(e) {
    // clearFormErrors(e.target);
    // this.previewTarget.innerHtml = '...';
  }

  complete() {
    // debugger;
  }

  success(e) {
    const data = e.detail[2].response;
    this.previewTarget.innerHTML = data;
  }

  error() {
    // debugger;
  }
}
