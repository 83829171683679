import { Controller } from 'stimulus';
import { renderFormError } from 'stlib';
import Turbolinks from 'turbolinks';
import Rails from '@rails/ujs';
// import Pikaday from 'pikaday';
// import 'pikaday/css/pikaday.css';
// import 'pikaday/css/theme.css';
// import 'pikaday/css/triangle.css';

export default class extends Controller {
  static targets = [
    'backlink',
    'savelink',
    'form',
    'date',
    'panel',
    'tab',
    'error'
  ];

  initialize() {}

  _isModal() {
    const modal = document.getElementById('modal');
    return modal && modal.classList.contains('is-active');
  }

  // Sets id to form so submit button can reference it
  connect() {
    let formId = this.formTarget.getAttribute('id');
    if (!formId) {
      formId = `f${Math.floor(Math.random() * 100).toString(10)}`;
      this.formTarget.setAttribute('id', formId);
    }

    if (this.hasSavelinkTarget && this.hasFormTarget) {
      this.savelinkTarget.setAttribute('form', formId);
    }

    if (this.hasBacklinkTarget) {
      const reExp = /[\?\&]back=([a-z0-9\-\%]+)\&?/i;
      const { pathname, search, hash } = window.location;
      const back = reExp.exec(search);

      if (back && back[1]) {
        this.backlinkTarget.setAttribute('href', decodeURIComponent(back[1]));
      }
    }

    // this.dateTargets.forEach(target => {
    //   new Pikaday({ field: target, format: 'DD.MM.YYYY', });
    // });
  }

  send(e) {
    e.preventDefault();
    Rails.fire(this.formTarget, 'submit');
  }

  submit() {
    // clearFormErrors(this.formTarget);
    this.data.set('sending', '1');
  }

  complete() {
    // clearFormErrors(this.formTarget);
    this.data.set('sending', '0');
  }

  // Prevent turbolinks redirection. Eg this:
  //
  //     Turbolinks.clearCache()
  //     Turbolinks.visit(e.detail[0].data.redirect, {"action":"replace"})
  redirect(e) {
    if (this.data.get('sending') === '1' && this.data.get('redirect') !== '1') {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  success(e) {
    if (this._isModal()) {
      e.preventDefault();
      const modal = document.getElementById('modal');

      modal.classList.remove('is-active');
      modal.dataset.result = '1';
      modal.innerHTML = '';
      window.dispatchEvent(new Event('formok'));
    }

    // If closed, history.back page is reloaded
    if (this.hasBacklinkTarget) {
      this.data.set('redirect', '1');
      Turbolinks.visit(this.backlinkTarget.href, { action: 'replace' });
    }
  }

  close(e) {
    e.preventDefault();

    const modal = document.getElementById('modal');

    if (
      (!modal || !modal.classList.contains('is-active')) &&
      this.hasBacklinkTarget
    ) {
      this.data.set('redirect', '1');
      Turbolinks.visit(this.backlinkTarget.href, { action: 'replace' });
      return;
    }

    modal.dataset.result = '0';
    modal.classList.remove('is-active');
    modal.innerHTML = '';

    if (this.hasBacklinkTarget) {
      Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(
        this.backlinkTarget.href,
        Turbolinks.uuid()
      );
    }
  }

  keydown(e) {
    let isEscape = false;

    if ('key' in e) {
      isEscape = e.key === 'Escape' || e.key === 'Esc';
    } else {
      isEscape = e.keyCode === 27;
    }
    if (isEscape) {
      if (this.formChanged(this.formTarget).length > 0) {
        alert('You have unsaved changes in this form');
        return;
      }

      this.close(e);
    }
  }

  // errors: [status: "401", title: "Unauthorized"]}
  // errors: [status: "402", title: "Validation", meta: {erros: ...}
  error(e) {
    const [data, message] = e.detail;
    const dataError = renderFormError(this.formTarget, data);

    const frmErr = this.formTarget.querySelector('.form-error');
    if (frmErr) {
      frmErr.innerHTML = dataError || message;
    }

    if (window.grecaptcha) window.grecaptcha.reset();
  }

  // Find changed elements
  formChanged(form) {
    const changed = [];

    if (!form || !form.nodeName || form.nodeName.toLowerCase() !== 'form') {
      return false;
    }

    Array.from(form.elements).forEach(el => {
      let c = false;
      let def = 0;

      switch (el.nodeName.toLowerCase()) {
        // select boxes
        case 'select':
          Array.from(el.options).forEach((opt, idx) => {
            c = c || (opt.selected !== opt.defaultSelected);
            if (opt.defaultSelected) def = idx;
          });
          if (c && !el.multiple) {
            c = def !== el.selectedIndex;
          }
          break;

        // input / textarea
        case 'textarea':
        case 'input':
          switch (el.type.toLowerCase()) {
            case 'checkbox':
            case 'radio':
              // checkbox / radio
              c = el.checked !== el.defaultChecked;
              break;
            default:
              // standard values
              c = el.value !== el.defaultValue;
              break;
          }
          break;

        default:
          break;
      }

      if (c) changed.push(el);
    });

    return changed;
  }
}
