import { Controller } from 'stimulus';
import { clearFormErrors, renderFormError } from 'stlib';
// import Turbolinks from 'turbolinks';

export default class extends Controller {
  static targets = ['error', 'form'];
  // connect() {}

  submit(e) {
    // clearFormErrors(e.target);
    // this.errorTarget.innerText = '...';
  }

  // complete() {}

  // errors: [status: "401", title: "Unauthorized"]}
  // errors: [status: "402", title: "Validation", meta: {erros: ...}
  error(e) {
    let [data, message] = e.detail;

    if (typeof data === 'string') {
      const m = JSON.parse(data);
      if (m['errors']) {
        data = m;
      }
    }

    if (typeof data !== 'object') {
      this.errorTarget.innerText = message;
      return;
    }

    if (typeof data.errors === 'object' && typeof data.errors[0] === 'object') {
      const err = data.errors[0];

      this.errorTarget.innerText = err.detail || err.title;

      if (typeof err.meta === 'object') {
        this.renderErrors(e.target, err.meta.errors);
      }

      return;
    }
    if (typeof data.errors === 'object' && !Array.isArray(data.errors)) {
      this.renderErrors(e.target, data.errors);
    }

    if (typeof data.error === 'string') {
      this.errorTarget.innerText = data.error;
    }
  }

  success(e) {
    // Turbolinks.clearCache()
    // Turbolinks.visit(e.detail[0].data.redirect, {"action":"replace"})
  }

  //   errors: { email:["can't be blank"], password:["can't be blank"]...
  renderErrors(form, errors) {
    clearFormErrors(form);

    const modelName = 'user';

    Object.keys(errors).forEach((field) => {
      const messages = errors[field];

      if (field === 'base') {
        this.errorTarget.innerText = messages
          .map((m) => m.charAt(0).toUpperCase() + m.slice(1))
          .join('. ');
      }

      const fgroup = form.querySelector(`.${modelName}_${field}`);

      if (fgroup == null) {
        return;
      }

      const input = fgroup.querySelector('input, select, textarea');

      if (input == null) {
        return;
      }

      input.classList.add('has-error');

      const help = fgroup.querySelector('.help');
      help.classList.add('is-danger');
      help.classList.add('has-text-right');
      help.innerText = messages
        .map((m) => m.charAt(0).toUpperCase() + m.slice(1))
        .join('. ');
    });
  }
}
