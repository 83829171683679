export function clearFormErrors(form) {
  form
    .querySelectorAll('input')
    .forEach(el => el.classList.remove('is-danger', 'has-error'));

  form.querySelectorAll('p.help').forEach(el => (el.innerText = ''));
}

export function clearFormFields(form) {
  form.querySelectorAll('input').forEach(el => {
    el.val('');
    el.removeAttribute('checked');
    el.removeAttribute('selected');
  });

  // not(':button, :submit, :reset, :hidden')
}

// meta: {
//   model: 'user'
//   errors: { email:["can't be blank"], password:["can't be blank"]...
export function renderFormErrors(form, meta) {
  clearFormErrors(form);

  const modelName = meta.model || 'user';

  Object.keys(meta.errors).forEach((field) => {
    const fgroup = form.querySelector(`.${modelName}_${field}`);
    const messages = meta.errors[field];

    if (fgroup == null) {
      return;
    }

    const input = fgroup.querySelector('input, select, textarea');

    if (input == null) {
      return;
    }

    input.classList.add('has-error', 'is-danger');

    const help = fgroup.querySelector('.help');
    help.innerText = messages
      .map((m) => m.charAt(0).toUpperCase() + m.slice(1))
      .join('. ');
    help.classList.add('has-text-danger');
  });
}

export function renderFormError(form, data) {
  if (typeof data === 'string') {
    data = JSON.parse(data);
  }

  if (
    typeof data === 'object' &&
    typeof data.errors === 'object' &&
    typeof data.errors[0] === 'object'
  ) {
    const err = data.errors[0];

    if (typeof err.meta === 'object') {
      renderFormErrors(form, err.meta);
    }

    return err.title || err.detail;
  }

  return null;
}

// path polyfill
export function eventPath(evt) {
  const path = (evt.composedPath && evt.composedPath()) || evt.path;
  const { target } = evt;

  if (path != null) {
    // Safari doesn't include Window, but it should.
    return path.indexOf(window) < 0 ? path.concat(window) : path;
  }

  if (target === window) {
    return [window];
  }

  function getParents(node, memo = []) {
    const { parentNode } = node;

    if (!parentNode) {
      return memo;
    }

    return getParents(parentNode, memo.concat(parentNode));
  }

  return [target].concat(getParents(target), window);
}
