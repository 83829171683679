import { Controller } from 'stimulus';
import { eventPath } from 'stlib';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['menu', 'button', 'notifications'];

  toggle(e) {
    if (eventPath(e).includes(this.menuTarget)) {
      e.stopPropagation();
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    this.buttonTarget.classList.toggle('is-active');
    this.menuTarget.classList.toggle('is-active');
  }

  pullup(e) {
    if (!this.menuTarget.classList.contains('is-active')) {
      return;
    }
    if (eventPath(e).includes(this.menuTarget)) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    this.buttonTarget.classList.remove('is-active');
    this.menuTarget.classList.remove('is-active');
  }

  sidebar(e) {
    const aside = document.getElementById('sidebar');

    e.preventDefault();
    aside.classList.toggle('is-hidden-mobile');
  }

  loadNotifications(e) {
    if (!e.currentTarget.classList.contains('is-active')) {
      return;
    }

    this.notificationsTarget.innerHTML =
      '<progress class="progress is-small is-warning" max="100">15%</progress>';

    Rails.ajax({
      url: '/n/unread',
      type: 'GET',
      dataType: 'html',
      error: data => {
        this.notificationsTarget.innerHTML = '<pre>(server error)</pre>';
      },
      success: (data, status, xhr) => {
        this.notificationsTarget.innerHTML = xhr.response;
      },
      complete: (xhr, status) => {
        const a = document.createElement('a');
        a.classList.add('navbar-item');
        a.href = '/n'
        a.innerText = 'Notification list...';
        this.notificationsTarget.appendChild(a);
      }
    });
  }

  notifyMarkRead(e) {
    const id = e.currentTarget.dataset.id;
    const data = new FormData();

    data.append('notification[id]', id);
    data.append('notification[read_at]', new Date());

    Rails.ajax({ url: `/n/${id}`, type: 'PATCH', data: data });
  }

  foo(e) {
    e.stopPropagation();
  }
}
