import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['field', 'destory', 'note', 'editbutton'];

  connect() {
    this.calculate();
  }

  hide(e) {
    e.preventDefault();

    this.destoryTarget.value = '1';
    this.element.classList.add('is-hidden');
    this.calculate();
  }

  edit() {
    if (!this.hasNoteTarget) {
      return;
    }

    const id = this.element.dataset.id;
    const textEl = document.getElementById('x_' + id);

    if (this.data.get('editing') === '1') {
      this.noteTarget.innerHTML = textEl.value;
      this.data.set('editing', '0');
      this.editbuttonTarget.innerText = 'Note';
      return;
    }

    this.noteTarget.innerHTML = `
      <trix-editor input="x_${id}"
                   class="trix-content"
                   data-controller="editor"
                   data-action="@trix-change->editor#click"
                   data-target="editor.editor"
                   data-direct-upload-url="${this.noteTarget.dataset.url}"
                   data-blob-url-template="${this.noteTarget.dataset.template}">
      </trix-editor>`;

    this.editbuttonTarget.innerText = 'Save';
    this.data.set('editing', '1');
  }

  getFields() {
    const fields = {};
    this.fieldTargets.forEach(field => {
      if (field.dataset.id) {
        fields[field.dataset.id] = field;
      }
    });
    return fields;
  }

  recalc(event) {
    event.preventDefault();
    event.stopPropagation();

    this.calculate();
  }

  calculate(fields) {
    const f = fields || this.getFields();

    if (this.element.classList.contains('is-hidden')) {
      f.total.value = '';
    } else {
      f.total.value = Number(f.qty_out.value || 1) * Number(f.price.value);
    }

    this.element.dispatchEvent(
      new CustomEvent('items:invalidate', { bubbles: true })
    );
  }

  update(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const fields = this.getFields();

    fields.art_id.value = e.detail.value;
    fields.art_code.value = e.detail.code;
    fields.art.value = e.detail.textValue;
    fields.price.value = e.detail.price;
    fields.qty_out.value = e.detail.qty || 1;

    this.calculate(fields);
  }
}
