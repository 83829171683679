import { Controller } from 'stimulus';
// import { Trix } from 'trix';

export default class extends Controller {
  static targets = ['editor'];

  connect() {}

  change(event) {
    if (event.target === this.editorTarget) {
    }
  }
}